import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";

/**
 * Base
 */
// Debug
// const gui = new GUI();

//first stage star texture
const textureLoader = new THREE.TextureLoader();
const particleTexture = textureLoader.load("/textures/particles/9.png");
const particleTexture1 = textureLoader.load("/textures/particles/9.png");
const particleTexture2 = textureLoader.load("/textures/particles/8.png");
const particleTexture3 = textureLoader.load("/textures/particles/12.png");
const galaxyTexture = textureLoader.load("/textures/particles/4.png");

// starsColor = {};

// Canvas
const canvas = document.querySelector("canvas.webgl-2");

// Scene
const scene = new THREE.Scene();

/**
 * Galaxy
 */
const parameters = {};
parameters.count = 500000;
parameters.size = 0.02;
parameters.radius = 10;
parameters.branches = 3;
parameters.spin = 1;
parameters.randomness = 2;
parameters.randomnessPower = 2;
parameters.insideColor = "#ff6030";
parameters.outsideColor = "#1074b1";

let geometry = null;
let material = null;
let points = null;

// geometry
const generateGalaxy = () => {
  //destroy old galaxy
  if (points !== null) {
    geometry.dispose();
    material.dispose();
    scene.remove(points);
  }

  // geometry
  geometry = new THREE.BufferGeometry();
  const positions = new Float32Array(parameters.count * 3);
  const colors = new Float32Array(parameters.count * 3);

  const colorInside = new THREE.Color(parameters.insideColor);
  const colorOutside = new THREE.Color(parameters.outsideColor);

  for (let i = 0; i < parameters.count; i++) {
    const i3 = i * 3;

    // position
    const radius = Math.random() * parameters.radius;
    const spinAngle = radius * parameters.spin;
    const branchAngle = ((i % parameters.branches) / parameters.branches) * Math.PI * 2;

    const randomX =
      Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
    const randomY =
      Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
    const randomZ =
      Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);

    positions[i3 + 0] = Math.cos(branchAngle + spinAngle) * radius + randomX;
    positions[i3 + 1] = randomY;
    positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;

    // color
    const mixedColor = colorInside.clone();
    mixedColor.lerp(colorOutside, radius / parameters.radius);

    colors[i3 + 0] = mixedColor.r;
    colors[i3 + 1] = mixedColor.g;
    colors[i3 + 2] = mixedColor.b;
  }
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  // material
  material = new THREE.PointsMaterial({
    size: parameters.size,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true,
  });
  material.transparent = true;
  material.alphaMap = galaxyTexture;

  // points
  points = new THREE.Points(geometry, material);
  scene.add(points);
  points.rotation.x = 3.14 / 8;
  points.rotation.z = 3.14 / 50;
};

generateGalaxy();
points.position.z = -100;

// gui.add(parameters, "count").min(100).max(1000000).step(1000).onFinishChange(generateGalaxy);
// gui.add(parameters, "size").min(0.01).max(0.1).step(0.001).onFinishChange(generateGalaxy);
// gui.add(parameters, "radius").min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy);
// gui.add(parameters, "branches").min(2).max(20).step(1).onFinishChange(generateGalaxy);
// gui.add(parameters, "spin").min(-5).max(5).step(0.001).onFinishChange(generateGalaxy);
// gui.add(parameters, "randomness").min(0).max(2).step(0.001).onFinishChange(generateGalaxy);
// gui.add(parameters, "randomnessPower").min(1).max(10).step(0.001).onFinishChange(generateGalaxy);
// gui.addColor(parameters, "insideColor").onFinishChange(generateGalaxy);
// gui.addColor(parameters, "outsideColor").onFinishChange(generateGalaxy);

//far aways stars //////////////////////////////////////////
function farStarsBuilder(particleTexture, farStarsCount) {
  const farStarsParticleGeometry = new THREE.BufferGeometry(1, 32, 32);

  const farStarsPositions = new Float32Array(farStarsCount * 3);
  const farStarsColors = new Float32Array(farStarsCount * 3);

  for (let i = 0; i < farStarsCount * 3; i++) {
    farStarsPositions[i] = (Math.random() - 0.5) * 200;
    farStarsColors[i] = Math.random();
  }

  farStarsParticleGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(farStarsPositions, 3)
  );
  farStarsParticleGeometry.setAttribute("color", new THREE.BufferAttribute(farStarsColors, 3));

  // material
  const farStarsParticlesMaterial = new THREE.PointsMaterial({
    size: 0.1,
    sizeAttenuation: true,
  });
  farStarsParticlesMaterial.transparent = true;
  farStarsParticlesMaterial.alphaMap = particleTexture;
  farStarsParticlesMaterial.depthWrite = false;
  farStarsParticlesMaterial.blending = THREE.AdditiveBlending;
  farStarsParticlesMaterial.vertexColors = true;

  const farStarParticle = new THREE.Points(farStarsParticleGeometry, farStarsParticlesMaterial);
  scene.add(farStarParticle);
}
farStarsBuilder(particleTexture1, 1000);
farStarsBuilder(particleTexture2, 1000);
farStarsBuilder(particleTexture3, 2000);

//first stage stars /////////////////////////////////////////////////////////
// particles
//geometry
function firstStarsBuilder(particleTexture, position, firstStarsCount) {
  const firstStarsParticleGeometry = new THREE.BufferGeometry(1, 32, 32);

  const firstStarsPositions = new Float32Array(firstStarsCount * 3);
  const firstStarsColors = new Float32Array(firstStarsCount * 3);

  for (let i = 0; i < firstStarsCount * 3; i++) {
    firstStarsPositions[i] = (Math.random() - 0.5) * 10;
    firstStarsColors[i] = Math.random();
  }

  firstStarsParticleGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(firstStarsPositions, 3)
  );
  firstStarsParticleGeometry.setAttribute("color", new THREE.BufferAttribute(firstStarsColors, 3));

  // material
  const particlesMaterial1 = new THREE.PointsMaterial({
    size: 0.06,
    sizeAttenuation: true,
  });
  particlesMaterial1.transparent = true;
  particlesMaterial1.alphaMap = particleTexture;
  particlesMaterial1.depthWrite = false;
  particlesMaterial1.blending = THREE.AdditiveBlending;
  particlesMaterial1.vertexColors = true;

  const particle = new THREE.Points(firstStarsParticleGeometry, particlesMaterial1);
  particle.position.z = position;
  scene.add(particle);
  return particle;
}

const particle11 = firstStarsBuilder(particleTexture1, 40, 3000);
const particle12 = firstStarsBuilder(particleTexture2, 40, 3000);
const particle13 = firstStarsBuilder(particleTexture3, 40, 3000);

const particle21 = firstStarsBuilder(particleTexture1, 50, 3000);
const particle22 = firstStarsBuilder(particleTexture2, 50, 3000);
const particle23 = firstStarsBuilder(particleTexture3, 50, 3000);

const particle31 = firstStarsBuilder(particleTexture1, 60, 5000);
const particle32 = firstStarsBuilder(particleTexture2, 60, 5000);
const particle33 = firstStarsBuilder(particleTexture3, 60, 5000);

const particle41 = firstStarsBuilder(particleTexture1, 70, 5000);
const particle42 = firstStarsBuilder(particleTexture2, 70, 5000);
const particle43 = firstStarsBuilder(particleTexture3, 70, 5000);

// animation camera
let endOfFirst = false;
let endOfSeceond = true;
const cl1 = new THREE.Clock();
const cl2 = new THREE.Clock();
function cameraAnimation(zSpeed, deltaTime) {
  const timer = cl2.getElapsedTime();
  if (!endOfFirst) {
    //update camera position
    if (timer >= 6) {
      camera.position.z -= zSpeed * deltaTime * 2;
    } else {
      camera.position.z -= (zSpeed * deltaTime * -(1.5 - timer)) / 15;
    }
    if (camera.position.z <= 17) {
      endOfFirst = true;
      endOfSeceond = false;
      controls.enabled = true;
      scene.remove(
        particle11,
        particle12,
        particle13,
        particle21,
        particle22,
        particle23,
        particle31,
        particle32,
        particle33,
        particle41,
        particle42,
        particle43
      );
    }

    //animate galaxy
    if (points.position.z <= 0 && camera.position.z <= 38) {
      points.position.z += zSpeed * deltaTime * 15;
    }
  }
  if (!endOfSeceond) {
    const elapsedTime = cl1.getElapsedTime();
    //animate camera
    camera.position.z -= (deltaTime * zSpeed) / 10;
    camera.position.x = Math.sin(elapsedTime / 8) * 8;
    camera.position.y = Math.sin(elapsedTime / 8) * 8;
    window.addEventListener("click", () => {
      endOfSeceond = true;
    });
    window.addEventListener("ontouchstart", () => {
      endOfSeceond = true;
    });
    if (camera.position.z <= -14) {
      endOfSeceond = true;
    }
  }
}

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 70;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enabled = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

//GSAP
let hasAnimationRun = false;
function textAnimation() {
  if (hasAnimationRun) return;
  const introTexts = document.querySelector(".intro-galaxy");
  const tl = gsap.timeline();
  tl.fromTo(
    introTexts,
    { opacity: 0, x: -100 }, // Start with invisible and slightly below
    {
      opacity: 1,
      x: 0,
      duration: 1,
      ease: "power2.out",
      delay: 1,
    }
  ).to(introTexts, {
    opacity: 0,
    x: 100, // Move slightly up while disappearing
    duration: 0.5,
    ease: "power2.inOut",
    delay: 3, // Keep "Hi" visible for a while
  });
  hasAnimationRun = true;
}

// scroll y
let StartAnimation = false;

window.addEventListener("scroll", () => {
  if (window.scrollY > window.innerHeight * 2.6) {
    StartAnimation = true;
    textAnimation();
  }
});

/**
 * Animate
 */
const clock = new THREE.Clock();
const zSpeed = 10;
let deltaTime = 0;
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  if (StartAnimation) {
    cameraAnimation(zSpeed, deltaTime);
  }

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
