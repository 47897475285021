import gsap from "gsap";

const introTexts = document.querySelectorAll(".intro-text");

const introTwoText = document.querySelectorAll(".section-2-intro");

const tl = gsap.timeline();
const tl2 = gsap.timeline();
const tl3 = gsap.timeline();

tl.fromTo(
  introTexts[0],
  { opacity: 0, y: 50 }, // Start with invisible and slightly below
  {
    opacity: 1,
    y: 0,
    duration: 1,
    ease: "power2.out",
    delay: 1,
  }
).to(introTexts[0], {
  opacity: 0,
  y: -50, // Move slightly up while disappearing
  duration: 0.5,
  ease: "power2.inOut",
  delay: 0.5, // Keep "Hi" visible for a while
});

// Add animations for the second text ("I")
tl.fromTo(
  introTexts[1],
  { opacity: 0, x: -50 }, // Start with invisible and slightly below
  {
    opacity: 1,
    x: 0,
    duration: 1,
    ease: "power2.out",
  },
  "+=0.3" // Start after a slight delay once "Hi" disappears
);
tl.fromTo(
  introTexts[2],
  { opacity: 0, x: -50, y: "4rem" }, // Start with invisible and slightly below
  {
    opacity: 1,
    x: 30,
    y: "4rem",
    duration: 1,
    ease: "power2.out",
  },
  "+=0.3" // Start after a slight delay once "Hi" disappears
);

// intro Two Text
function secondIntro() {
  if (window.scrollY > window.innerHeight / 1.2) {
    tl2.fromTo(
      introTwoText[0],
      { opacity: 0, x: -150 },
      {
        opacity: 1,
        duration: 0.8,
        x: 0,
        ease: "power2.out",
      }
    );
    tl2.fromTo(
      introTwoText[1],
      { opacity: 0, x: +150 },
      {
        opacity: 1,
        duration: 0.8,
        x: 0,
        ease: "power2.out",
      }
    );
    window.removeEventListener("scroll", secondIntro);
  }
}

window.addEventListener("scroll", secondIntro);

// cv animation
const cvFirstPart = document.querySelector(".first-part-animation");
const cvSecondPart = document.querySelector(".second-part-animation");
const cvThreadPart = document.querySelector(".Thread-part-animation");

function cvIntro() {
  cvThreadPart.style.opacity = 0;
  if (window.scrollY > window.innerHeight * 1.6) {
    tl3.fromTo(
      cvFirstPart,
      { opacity: 0, y: -200 },
      {
        opacity: 1,
        duration: 1,
        y: 0,
        ease: "power2.out",
      }
    );
    tl3.fromTo(
      cvSecondPart,
      { opacity: 0, y: +200 },
      {
        opacity: 1,
        duration: 1,
        y: 0,
        ease: "power2.out",
      }
    );
    tl3.fromTo(
      cvThreadPart,
      { opacity: 0, y: +200 },
      {
        opacity: 1,
        duration: 1,
        y: 0,
        ease: "power2.out",
      }
    );
    window.removeEventListener("scroll", cvIntro);
  }
}

window.addEventListener("scroll", cvIntro);
