import * as THREE from "three";
import gsap from "gsap";
import { GLTFLoader } from "three/examples/jsm/Addons.js";

/**
 * Debug
 */
// const gui = new GUI();

const parameters = {
  materialColor: "#ffeded",
};

// gui.addColor(parameters, "materialColor").onChange(() => {
//   material.color.set(parameters.materialColor);
//   particlesMatrial.color.set(parameters.materialColor);
// });

// loud texture

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// models
let personModel = null;

const gltfLoader = new GLTFLoader();
gltfLoader.load("./models/head1/scene.gltf", (gltf) => {
  personModel = gltf.scene;
  personModel.scale.set(0.5, 0.5, 0.5);
  personModel.position.x = 0;
  personModel.position.z = -1;
  scene.add(personModel);
});

/**
 * object
 */
// texture
const textureLoader = new THREE.TextureLoader();
const gradiantTexture = textureLoader.load("textures/gradients/3.jpg");
gradiantTexture.magFilter = THREE.NearestFilter;

const particleTexture1 = textureLoader.load("/textures/particles/1.png");
const particleTexture2 = textureLoader.load("/textures/particles/4.png");
const particleTexture3 = textureLoader.load("/textures/particles/9.png");
const particleTexture4 = textureLoader.load("/textures/particles/13.png");

// material
const material = new THREE.MeshToonMaterial({
  color: parameters.materialColor,
  gradientMap: gradiantTexture,
});
//mesh
const objectDistance = 4;

const mesh1 = new THREE.Mesh(new THREE.TorusGeometry(1, 0.4, 16, 60), material);
const mesh2 = new THREE.Mesh(new THREE.ConeGeometry(1, 2, 32), material);
const mesh3 = new THREE.Mesh(new THREE.TorusKnotGeometry(0.8, 0.35, 100, 16), material);

mesh1.position.y = objectDistance * 0;
mesh2.position.y = -objectDistance * 1;
mesh3.position.y = -objectDistance * 2;

mesh1.position.x = 2;
mesh2.position.x = -2;
mesh3.position.x = 2;

// scene.add(mesh2, mesh3);
const sectionMeshes = [mesh2, mesh3];

// particle
function makePoints(particleTexture, paritcleCount) {
  // const paritcleCount = 200;
  const positions = new Float32Array(paritcleCount * 3);

  for (let i = 0; i < paritcleCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] = objectDistance * 0.5 - Math.random() * objectDistance * 2.5;
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
  }
  const particlesGeometry = new THREE.BufferGeometry();
  particlesGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

  // material
  const particlesMatrial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.12,
  });
  particlesMatrial.transparent = true;
  particlesMatrial.alphaMap = particleTexture;

  // Points
  const particles = new THREE.Points(particlesGeometry, particlesMatrial);
  scene.add(particles);
}
makePoints(particleTexture1, 25);
makePoints(particleTexture2, 65);
makePoints(particleTexture3, 75);
makePoints(particleTexture4, 75);

// light
const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
directionalLight.position.set(1, 1, 1);
scene.add(directionalLight);

// Raycasting
const raycaster = new THREE.Raycaster();
let currentIntersect = null;
let personeIntersect = null;

//mouse event and cursor
const cursor = {};
cursor.x = 0;
cursor.y = 0;
const mouse = new THREE.Vector2();
let mouseCapcher = {};
mouseCapcher.color = false;
mouseCapcher.hit = false;
mouseCapcher.scrollY = false;
mouseCapcher.direction = true;

window.addEventListener("mousemove", (event) => {
  mouse.x = (event.clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;
  // cursor
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = event.clientY / sizes.height - 0.5;
});

window.addEventListener("click", () => {
  if (currentIntersect) {
  }
  if (personeIntersect) {
    mouseCapcher.color = true;
    mouseCapcher.hit = true;
  }
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 6;
cameraGroup.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

//scoll
let scrollY = window.scrollY;
// let currentSection = 0;

window.addEventListener("scroll", () => {
  scrollY = window.scrollY;
  //
  mouseCapcher.scrollY = true;

  // const newSection = Math.round(scrollY / sizes.height);
  // if (newSection != currentSection) {
  //   currentSection = newSection;
  //   gsap.to(sectionMeshes[currentSection].rotation, {
  //     duration: 1.5,
  //     ease: "power2.inOut",
  //     x: "+=6",
  //     y: "+=3",
  //     z: "+=1.5",
  //   });
  // }
});

/**
 * Animate
 */
const clock = new THREE.Clock();
let perviousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  // delta time
  const deltaTime = elapsedTime - perviousTime;
  perviousTime = elapsedTime;

  //animate camera
  camera.position.y = (-scrollY / sizes.height) * objectDistance;

  const parallaxX = cursor.x * 0.5;
  const parallaxY = -cursor.y * 0.5;
  cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
  cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime;
  // cameraGroup.position.z = -parallaxX;

  //animation meshes
  // for (const mesh of sectionMeshes) {
  //   mesh.rotation.x += deltaTime * 0.1;
  //   mesh.rotation.y += deltaTime * 0.12;
  // }

  // raycasting animation
  // Cast a ray from the mouse and handle events
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects(sectionMeshes);

  if (intersects.length > 0) {
    // Mouse is over an object
    const hoveredObject = intersects[0].object;

    if (currentIntersect !== hoveredObject) {
      // Mouse entered a new object
      if (currentIntersect) {
        // Reset scale of previous object
        currentIntersect.scale.set(1, 1, 1);
      }

      hoveredObject.scale.set(1.2, 1.2, 1.2);
      currentIntersect = hoveredObject;
    }
  } else {
    // Mouse is not over any object
    if (currentIntersect) {
      currentIntersect.scale.set(1, 1, 1);
      currentIntersect = null;
    }
  }

  if (personModel) {
    const modelIntersect = raycaster.intersectObject(personModel);
    if (modelIntersect.length) {
      personeIntersect = modelIntersect[0].object;
      personModel.scale.set(1.1, 1.1, 1.1);
      document.body.style.cursor = "pointer";
    } else {
      personeIntersect = null;
      personModel.scale.set(1, 1, 1);
      document.body.style.cursor = "default";
    }
    //animation
    personModel.rotation.y += deltaTime * 0.2;
    // if (mouseCapcher.color) {
    //   personeIntersect.material.color.set(1, 2, 3);
    //   mouseCapcher.color = false;
    // }
    //hitting animation
    if (mouseCapcher.hit) {
      if (personModel.position.z > -16 && mouseCapcher.direction) {
        personModel.position.z -= deltaTime * 40;
        personModel.rotation.x -= deltaTime * Math.random() * 30;
        personModel.rotation.y += deltaTime * Math.random() * 5;
        if (personModel.position.z < -16) {
          mouseCapcher.direction = false;
          mouseCapcher.hit = false;
        }
      } else if (personModel.position.z < -1 && !mouseCapcher.direction) {
        "direction", mouseCapcher.direction;
        personModel.position.z += deltaTime * 60;
        personModel.position.x = 0;
        personModel.rotation.x = 0;
        personModel.rotation.y = 0;
        if (personModel.position.z > -1) {
          mouseCapcher.direction = true;
          mouseCapcher.hit = false;
        }
      } else {
        mouseCapcher.hit = false;
        mouseCapcher.direction = true;
      }
    }
    //scroll animation
    if (mouseCapcher.scrollY) {
      if (personModel.position.z < -1) {
        personModel.position.z += 0.6;
        personModel.position.x = 0;
        personModel.rotation.x = 0;
        personModel.rotation.y = 0;
      } else {
        mouseCapcher.scrollY = false;
        mouseCapcher.direction = true;
      }
    }
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

// clean js heap

function cleanUp() {
  // Dispose geometries, materials, and textures
  scene.traverse((child) => {
    if (child.isMesh) {
      child.geometry.dispose();
      if (child.material.isMaterial) {
        child.material.dispose();
      }
    }
  });

  // Dispose particles
  particlesGeometry.dispose();
  particlesMatrial.dispose();

  // Dispose renderer
  renderer.dispose();
}

function removeEventListeners() {
  window.removeEventListener("mousemove", mouseMoveHandler);
  window.removeEventListener("scroll", scrollHandler);
  window.removeEventListener("resize", resizeHandler);
}

// event listener
window.addEventListener("beforeunload", () => {
  cleanUp();
  removeEventListeners();
  gsap.globalTimeline.clear();
});
